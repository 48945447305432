.pswp * {
  box-sizing: border-box;
}

.pswp-overview {
  opacity: 0.5;
  position: absolute;
  inset: 0px;
  z-index: 10;
  opacity: 0.005;
  opacity: 1;
  background: black;
}

.pswp__image-wrapper {
  position: fixed;
  inset: 5rem 5rem;
  overflow: auto;
  display: flex;
  align-items: center;
}

.pswp__image-inner-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 56rem;
}

.pswp__section + .pswp__section {
  margin-top: 1rem;
}

.pswp__heading {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  color: rgb(255, 255, 255);
}

.pswp__image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.pswp__image-button {
  aspect-ratio: 1 / 1;
  width: 8rem;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
}

.pswp__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
}

.pswp__image:hover {
  transform: scale(1.05);
}
